import React from 'react';
import Icon from "@mdi/react";
import {mdiFacebook, mdiInstagram, mdiWhatsapp} from "@mdi/js";
import Logo from "../../assets/brand/logo.svg";

interface Props {
  imageUrl: string;
  greeting: string;
  subtitle: string;
  links?: {
    instagram?: string;
    facebook?: string;
    whatsapp?: string;
  };
}

export default function PresentationCard({ imageUrl, greeting, subtitle, links }: Props) {
  return (
    <div className="rounded-lg mb-10 w-120 text-center flex flex-col items-center text-white">
      <div className="border border-gray-700 rounded-lg mb-5">
        <img src={Logo} alt="Flux Studio Logo" className="rounded-lg w-48 h-48 bg-indigo-50" />
      </div>
      <h1 className="text-3xl font-bold font-montserrat">{greeting}</h1>
      <p className="mt-2 mb-4 text-sm font-light">{subtitle}</p>
      {links ? (
        <div className="flex justify-center space-x-3">
          {links.instagram ? (
            <a href={links.instagram} target="_blank" rel="noreferrer">
              <Icon
                path={mdiInstagram}
                title="Instagram"
                color="#ffffff"
                size={1}
              />
            </a>
          ) : null}
          {links.facebook ? (
            <a href={links.facebook} target="_blank" rel="noreferrer">
              <Icon
                path={mdiFacebook}
                title="Facebook"
                color="#ffffff"
                size={1}
              />
            </a>
          ) : null}
          {links.whatsapp ? (
            <a href={links.whatsapp} target="_blank" rel="noreferrer">
              <Icon
                path={mdiWhatsapp}
                title="WhatsApp"
                color="#ffffff"
                size={1}
              />
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

