import React, {useEffect} from 'react';
import TextTransition, { presets } from 'react-text-transition';

interface Props {
  simple?: boolean;
}

const TEXTS = [
  'hace diseños modernos', 'hace aplicaciones moviles', 'hace sitios web', 'maneja redes sociales'
]

export default function TopBar({ simple }: Props) {
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="min-w-full text-center sticky top-0 backdrop-filter backdrop-blur-lg bg-opacity-30 bg-black">
      <p className="text-white p-2 font-light">
        Con un equipo que <TextTransition className="text-green-A700 font-bold" inline springConfig={presets.slow}>{TEXTS[index % TEXTS.length]}</TextTransition>
      </p>
    </div>
  );
}

