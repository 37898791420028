import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import WelcomePage from "../pages/WelcomePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <WelcomePage />,
  },
  {
    path: "/welcome",
    element: <WelcomePage />,
  },
]);

export default function Navigation() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}
