import React from 'react';

interface Props {
  simple?: boolean;
}

export default function Footer({ simple }: Props) {
  if (simple) {
    return (
      <div className="rounded-lg mt-10">
        <p className="text-gray-600 text-center text-xs md:text-base">
          Copyright © {new Date().getFullYear()} <span className="text-gray-500">Flux Studio</span> <br/> Con {'</>'} de{' '}
          <a href="https://www.juniorcarrillo.com/" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-gray-200 transition duration-300 delay-150">Junior Carrillo</a>
        </p>
      </div>
    );
  }

  return (
    <div className="rounded-lg mt-10">
      <p className="text-gray-600">
        Copyright © {new Date().getFullYear()} <span className="text-gray-500">Flux Studio</span> | Con {'</>'} de{' '}
        <a href="https://www.juniorcarrillo.com/" target="_blank" rel="noreferrer" className="text-gray-500 hover:text-gray-200 transition duration-300 delay-150">Junior Carrillo</a>.
      </p>
    </div>
  );
}

