import React from 'react';
import PresentationCard from "../../components/PresentationCard";
import {TipogratiasGratis, SolicitarCotizacion} from '../../assets/banners';
import WelcomeBanner from "../../components/WelcomeBanner";
import Footer from "../../components/Footer";
import TopBar from "../../components/TopBar";

export default function WelcomePage() {
  return (
    <div className="min-h-screen">
      <TopBar simple />
      <div className="p-10 flex flex-col justify-center items-center">
        <PresentationCard
          greeting="¡Flux Studio!"
          subtitle="Aquí tienes algunos enlaces útiles para ti."
          imageUrl="https://picsum.photos/300/300"
          links={{
            instagram: 'https://www.instagram.com/fluxstudio.ve',
            facebook: 'https://www.facebook.com/fluxstudio.ve',
            whatsapp: 'https://wa.me/+584127786458',
          }}
        />
        <WelcomeBanner
          newWindow
          alt="Tipografias Gratis"
          image={TipogratiasGratis}
          link="https://drive.google.com/drive/folders/11LX3UCBc3PVCJZHpF4HE6GF60aghm5CK?usp=sharing"
        />
        <WelcomeBanner
          newWindow
          alt="Solicitar cotización"
          image={SolicitarCotizacion}
          link="https://www.instagram.com/fluxstudio.ve"
        />
        <Footer simple />
      </div>
    </div>
  );
}

