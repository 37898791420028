import React from 'react';

interface Props {
  newWindow?: boolean
  link?: string;
  image: string;
  alt: string;
}

export default function WelcomeBanner({ image, alt, newWindow, link = '#' }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={link}
      rel="noreferrer"
      target={newWindow ? '_blank' : '_self'}
      className="mb-5 border border-gray-700 rounded-2xl cursor-pointer transition ease-in-out delay-150 duration-300 hover:scale-110">
      <img src={image} alt={alt} className="max-h-60 rounded-2xl" />
    </a>
  );
}

